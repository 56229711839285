<template>
  <el-dialog title="编辑分组" :visible.sync="editGroup" width="52%" :append-to-body="true">
    <div>
      <el-button size="small" type="primary" @click="addGroup">新建分组</el-button>
      <el-table :header-cell-style="{ 'background-color': '#F8F9FA' }" :data="dialogList" style="width: 100%; margin-top: 20px">
        <el-table-column align="center" label="分组名称" width="180">
          <template v-slot="{ row }">
            {{ !row.flag ? row.name : '' }}
            <el-input v-show="row.flag" placeholder="新建分组名" v-model="row.name"></el-input>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nums" label="图片数" width="180"> </el-table-column>
        <el-table-column align="center" label="创建时间">
          <template v-slot="{ row }">
            <div v-if="row.id !== 0 && row.create_time">
              {{ row.create_time | getDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template v-slot="{ row }">
            <el-button v-if="row.id !== 0" type="text" @click="addGroupSuccess(row)">{{ !row.flag ? '编辑' : '完成' }}</el-button>
            <el-button v-if="row.id !== 0" type="text" @click="delGroup(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { getDateformat } from '@/util/getDate'
export default {
  props: {
    editGroup: {
      type: Boolean,
      default: false
    },
    dialogList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeGroupName: ''
    }
  },
  methods: {
    // 新建分组
    addGroup() {
      if (this.dialogList.length > 1 && this.dialogList[1].nums === undefined) {
        this.$message.warning('请先完成上次添加')
        return
      }
      this.dialogList.splice(1, 0, { name: null, flag: true })
    },
    //完成图片分组添加
    addGroupSuccess(row) {
      if (row.name === null || row.name === '') {
        this.$message.warning('分类名称不能为空')
        return
      }
      this.activeGroupName = JSON.stringify(row.name)
      let that = this
      if (row.nums === undefined) {
        if (row.flag) {
          this.$axios
            .post(this.$api.sourceCenter.ImagesGroupAdd, {
              name: row.name
            })
            .then((res) => {
              if (res.code == 0) {
                this.$emit('addSuccess', row)
              }
            })
        }
      } else {
        if (row.flag) {
          if (this.activeGroupName == row.name) {
            row.flag = !row.flag
            return
          }
          this.$axios
            .post(this.$api.sourceCenter.ImagesGroupEdit, {
              name: row.name,
              id: row.id
            })
            .then((res) => {
              if (res.code != 0) {
                that.$message.error('修改失败')
              }
            })
        }
      }
      row.flag = !row.flag
    },
    // 删除图片分组
    delGroup(row) {
      let that = this
      if (row.id === undefined) {
        this.dialogList.splice(1, 1)
      } else {
        this.$axios
          .post(this.$api.sourceCenter.ImagesGroupDel, {
            id: row.id
          })
          .then((res) => {
            if (res.code != 0) {
              that.$message.error('删除失败')
            } else {
              if (that.activeGroupId == row.id) {
                that.activeGroupId = -1
              }
              let index = that.dialogList.findIndex((item) => item == row)
              this.$emit('delSuccess', index)
            }
          })
      }
    }
  },
  watch: {
    editGroup(val) {
      this.$emit('changeBox', val)
    }
  },
  filters: {
    getDate(val) {
      return getDateformat(val)
    }
  }
}
</script>

<style lang="less" scoped></style>
