<template>
  <div>
    <!-- 旧版本素材列表弹框 已弃用 -->
    <el-dialog
      title="图片"
      :visible.sync="dialogVisible"
      width="60%"
      append-to-body
      @close="handleClose"
    >
      <div class="options">
        <div class="left">
          <el-button size="small" @click="type = 2" :type="type == 2 ? 'primary' : ''">
            上传图片
          </el-button>
          <el-button
            v-if="url !== 2"
            size="small"
            @click="type = 1"
            :type="type == 1 ? 'primary' : ''"
            >图 片 库</el-button
          >
        </div>
        <div class="right">
          <el-form style="height: 32px" v-show="type == 1" :model="form" @submit.native.prevent>
            <el-form-item style="margin-bottom: 0px">
              <el-input
                size="small"
                placeholder="请输入内容"
                @keyup.enter.native="search"
                prefix-icon="el-icon-search"
                v-model="form.keywords"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 上传图片页面 -->
      <div class="uploadImg" v-if="type == 2">
        <div class="chooseClass">
          <p>上传到分组</p>
          <el-select v-model="groupId" placeholder="请选择">
            <el-option
              v-for="(item, index) in imgGroupList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button v-if="url !== 1" type="text" @click="goCenter">去设置分组</el-button>
        </div>
        <div class="showImg">
          <div
            class="imgBox"
            v-for="(item, index) in filesList"
            :key="index"
            @mouseenter="updateFlag(item, 1)"
            @mouseleave="updateFlag(item, 2)"
          >
            <img :src="item.src" alt="" />
            <div
              class="cropper"
              v-show="item.updateFlag || optionIndex == index"
              @click="Tailoring(item, index)"
            >
              裁剪
            </div>
            <i class="iconfont icon-guanbi" @click="deleteImg(index)"></i>
          </div>
          <div class="addImg">
            <input
              class="dropbox"
              :multiple="isMany"
              type="file"
              @change="getImgFile"
              ref="inputer"
              accept="image/png,image/jpeg,image/gif,image/jpg"
            />
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <!-- 裁剪页面 -->
      <div class="cropper-img" v-if="isTailoring">
        <div class="buttonBox">
          <el-button
            size="mini"
            v-show="returnOption.src !== undefined && returnOption.src != option.img"
            @click="returnImg"
            >撤销</el-button
          >
          <el-button size="mini" type="primary" @click="sureTailoring">裁剪</el-button>
          <el-button size="mini" @click="cancalTailoring">完成</el-button>
        </div>
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @imgLoad="imgLoad"
          @realTime="realTime"
        ></vue-cropper>
      </div>
      <!-- 图片库页面 -->
      <div class="img-library" v-if="type == 1">
        <div class="groupList">
          <div
            v-for="(item, index) in imgGroupList"
            :key="index"
            @mouseleave="enterGroup = -1"
            @mouseenter="enterGroup = item.id"
            @click="chooseGroupFn(item.id)"
            :style="{
              background: chooseGroup == item.id ? '#e5f2ff' : '',
              color: chooseGroup == item.id ? '#409EFF' : '',
              cursor: 'pointer'
            }"
            :class="[enterGroup == item.id ? 'enter' : '']"
          >
            <span>{{ item.name }}</span>
            <span>（{{ item.nums }}）</span>
          </div>
        </div>
        <div class="listBox">
          <ul class="img-librar-list">
            <li
              @click="selectImg(index)"
              v-for="(item, index) in list"
              :key="index"
              :style="{ 'margin-right': (index + 1) % 7 == 0 ? 0 : '' }"
            >
              <el-image :src="item.url"></el-image>
              <span class="selectImg-active" :style="{ opacity: item.isSelect ? 1 : 0 }">
                <i class="el-icon-check"></i>
              </span>
              <p>{{ item.name }}</p>
            </li>
            <No v-if="!list.length" />
          </ul>
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              :page-size="rows"
              layout="total, prev, pager, next"
              :total="total_number"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!--预览效果图-->
      <span slot="footer" class="dialog-footer" v-if="!isTailoring">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="uploadImg">确 定</el-button>
      </span>
    </el-dialog>
    <SetGroup
      :editGroup="editGroup"
      :dialogList="imgGroupList"
      @delSuccess="getImgClass"
      @addSuccess="getImgClass"
      @changeBox="changeBox"
    ></SetGroup>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
import SetGroup from '@/components/setGroup'
import _ from 'lodash'
export default {
  components: {
    VueCropper,
    SetGroup
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: !1
    },
    /**
     * 类别判断
     * 0-站点内正常上传图片
     * 1-站点外上传图片
     * 2-直播上传图片素材
     */
    url: {
      type: Number,
      default: 0
    },
    isMany: {
      type: Boolean,
      default: false
    },
    goUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editGroup: false,
      groupId: 0,
      list: [],
      isTailoring: !1,
      type: 1,
      option: {
        img: '', //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'png', //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 230, //默认生成截图框宽度
        autoCropHeight: 150, //默认生成截图框高度
        fixed: false, //是否开启截图框宽高固定比例
        fixedNumber: [1.53, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: false, //固定截图框大小，不允许改变
        canMove: true, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: true, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: true, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: '330px 250px' //图片默认渲染方式
      },
      // 裁剪图片在数组中的索引
      optionIndex: -1,
      returnOption: {},
      files: [],
      filesList: [],
      page: 1,
      rows: 21,
      form: {
        keywords: ''
      },
      total_number: 0,
      imgGroupList: [],
      enterGroup: -1,
      chooseGroup: 0,
      chooseImgListArr: []
    }
  },
  created() {
    if (this.url === 2) {
      this.type = 2
    }
    this.getImgClass()
  },
  mounted() {
    this.getImgList()
  },
  methods: {
    // 检测弹框的标杆
    changeBox(val) {
      this.editGroup = val
    },
    // 去设置图片分组
    goCenter() {
      this.editGroup = true
    },
    // 选择图片分组
    chooseGroupFn(id) {
      this.chooseGroup = id
      this.page = 1
      this.getImgList()
    },
    // 获取图片分组
    getImgClass() {
      let that = this
      this.$axios
        .post(
          this.url === 1 ? this.$api.sourceCenter.JwtList : this.$api.sourceCenter.ImagesGroupList,
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            if (!this.url) {
              res.result.list.shift()
            } else {
              this.chooseGroup = -1
              this.groupId = -1
            }
            that.imgGroupList = res.result.list
            that.imgGroupList.map((item) => {
              that.$set(item, 'flag', false)
            })
          } else {
            that.$message.error('获取图片分组出错')
          }
        })
    },
    // 取消裁剪
    cancalTailoring() {
      this.optionIndex = -1
      this.option.img = ''
      this.returnOption = {}
      this.isTailoring = false
    },
    // 取消此图片上传
    deleteImg(index) {
      this.filesList.splice(index, 1)
      if (index == this.optionIndex) {
        this.cancalTailoring()
      }
    },
    //确认裁剪此图片
    Tailoring(item, index) {
      this.optionIndex = index
      this.option.img = item.src
      this.isTailoring = true
    },
    // 返回裁剪之前
    returnImg() {
      this.filesList[this.optionIndex] = this.returnOption
      this.option.img = this.returnOption.src
    },
    // 显示裁剪
    updateFlag(item, index) {
      if (index == 1) {
        this.$set(item, 'updateFlag', true)
      } else {
        this.$set(item, 'updateFlag', false)
      }
    },
    // 搜索图片
    search() {
      this.getImgList()
    },
    //获取图片库
    getImgList() {
      if (this.url == 2) return
      this.$axios
        .post(this.url ? this.$api.user.JwtImagesList : this.$api.user.imgList, {
          page: this.page,
          rows: this.rows,
          keywords: this.form.keywords,
          group_id: this.chooseGroup
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list
            for (let i in list) {
              list[i].isSelect = !1
              list[i].path = list[i].url
              // list[i].name = list[i].name.substring(0, list[i].name.lastIndexOf('.'))
            }
            this.list = list
            this.total_number = res.result.total_number
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 将file文件转成base64 status-0=>获取上传的图片   1=>获取裁剪之后的图片
    changeBase64(that, item, status) {
      let reader = new FileReader()
      let img = new Image()
      img.src = window.URL.createObjectURL(item)
      img.onload = function () {
        //设置图片比列
        that.option.fixedNumber[0] = img.width / 100
        that.option.fixedNumber[1] = img.height / 100
      }
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        let res = { name: item.name, src: data, type: item.type, updateFlag: false }
        if (status == 0) {
          that.filesList.push(res)
        } else if (status == 1) {
          that.$set(that.filesList[that.optionIndex], 'src', data)
          that.option.img = data
        }
      }
      reader.readAsDataURL(item)
    },
    // 将base64位图片转换成二进制
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(',')[1])
      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      let ab = new ArrayBuffer(byteString.length)
      let ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: mimeString })
    },
    // 更改每页条数
    handleSizeChange(val) {
      this.rows = val
      this.getImgList()
    },
    // 更改页码
    handleCurrentChange(val) {
      this.page = val
      this.getImgList()
    },
    // 获取上传的所有图片
    getImgFile(e) {
      let that = this
      let files = this.$refs.inputer.files
      let filesArr = []
      let flag = true
      for (const key in files) {
        if (!isNaN(Number(key))) {
          filesArr.push(files[key])
        }
      }
      let arr = ['.png', '.jpg', '.jpeg', '.gif']
      if (files && filesArr.length > 0) {
        // 检索上传文件的后缀名是否符合格式
        filesArr.map((item) => {
          let str = item.name.lastIndexOf('.')
          let res = arr.findIndex((item1) => {
            return item1 == item.name.substr(str)
          })
          if (res == -1) {
            flag = false
          }
        })
        // 判断上传文件的后缀名是否符合格式
        if (!flag) {
          this.$message.warning('请上传image/png,image/jpeg,image/gif,image/jpg格式的图片')
          return
        }
        this.files = filesArr
        this.files.map((item) => {
          that.changeBase64(that, item, 0)
        })
      }
    },
    // 关闭弹框
    handleClose() {
      this.dialogVisible = !1
      this.filesList = []
      this.list.map((item) => {
        item.isSelect = !1
      })
      this.type = 1
      this.groupId = 0
      this.isTailoring = !1
      this.$emit('changeStatus', this.dialogVisible)
    },
    // 确认裁剪
    sureTailoring() {
      this.returnOption = _.cloneDeep(this.filesList[this.optionIndex])
      let files = null
      let that = this
      this.$refs.cropper.getCropBlob(async (data) => {
        // bold文件类转file文件
        files = new window.File([data], this.filesList[this.optionIndex].name, {
          type: this.filesList[this.optionIndex].type
        })
        that.changeBase64(that, files, 1)
      })
    },
    // 点击确认上传
    async uploadImg() {
      let len = this.filesList.length
      let that = this
      if (len == 0) {
        if (this.type == 2) {
          this.$message.warning('未上传任何图片')
        } else if (this.type == 1) {
          // let imglist = this.list.filter((i) => i.isSelect)
          if (this.chooseImgListArr.length == 0) {
            this.$message.warning('未选择任何图片')
            return
          }
          this.$emit('getImg', this.chooseImgListArr)
          this.dialogVisible = !1
        }
      } else {
        if (this.type == 2) {
          let imglist = []
          for (const key in this.filesList) {
            await this.uploadFunction(this.filesList[key], 2).then((res) => {
              if (that.url === 2) {
                imglist.push(res)
              } else {
                imglist.push({ path: res.path })
              }
            })
          }
          this.$emit('getImg', imglist, this.groupId)
          this.dialogVisible = !1
        } else {
          let imglist = this.list.filter((i) => i.isSelect)
          this.$emit('getImg', imglist, this.groupId)
          this.dialogVisible = !1
        }
      }
    },
    // 上传接口函数
    uploadFunction(item, statsu) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        let blob = this.dataURItoBlob(item.src)
        let files = new window.File([blob], item.name, { type: item.type })
        formData.append('files', files)
        formData.append('group_id', this.groupId)
        // 设置分组
        this.$axios
          .uploadFile(
            this.url === 1
              ? this.$api.user.SystemUpload
              : this.url === 0
              ? this.$api.user.addFiles
              : this.url === 2
              ? this.$api.push.liveRoom.liveImgUp
              : '',
            formData
          )
          .then((res) => {
            if (res.code == 0) {
              resolve(res.result)
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    //选择图片
    selectImg(i) {
      if (!this.isMany) {
        this.list[i].isSelect = !this.list[i].isSelect
        this.list.map((item, index) => {
          if (index !== i) {
            item.isSelect = !1
          }
        })
        this.chooseImgListArr[0] = this.list[i]
      } else {
        this.list[i].isSelect = !this.list[i].isSelect
        if (this.list[i].isSelect) {
          this.chooseImgListArr.push(this.list[i])
        } else {
          let index = this.chooseImgListArr.findIndex((item) => item.id === this.list[i].id)
          this.chooseImgListArr.splice(index, 1)
        }
      }
    },
    // 关闭弹框
    closeDia() {
      this.dialogVisible = !1
      this.$emit('closeDialog')
    },
    // 禁用浏览器默认拖拽行为
    onDrag: function (e) {
      e.stopPropagation()
      e.preventDefault()
    },
    // 拖拽上传
    onDrop: function (e) {
      e.stopPropagation()
      e.preventDefault()
      let that = this
      let filesArr = []
      let flag = true
      let dt = e.dataTransfer.files
      for (const key in dt) {
        if (!isNaN(Number(key))) {
          filesArr.push(dt[key])
        }
      }
      if (!this.isMany) {
        this.$message.warning('此处只能上传单张图片')
        return
      }
      if (filesArr && filesArr.length > 0) {
        let arr = ['.png', '.jpg', 'jpeg', '.gif']
        if (filesArr.length > 0) {
          // 检索上传文件的后缀名是否符合格式
          filesArr.map((item) => {
            let str = item.name.lastIndexOf('.')
            let res = arr.findIndex((item1) => {
              return item1 == item.name.substr(str)
            })
            if (res == -1) {
              flag = false
            }
          })
          // 判断上传文件的后缀名是否符合格式
          if (!flag) {
            this.$message.warning('请上传image/png,image/jpeg,image/gif,image/jpg格式的图片')
            return
          }
          this.files = filesArr
          this.files.map((item) => {
            that.changeBase64(that, item, 0)
          })
        }
      }
    }
  },
  watch: {
    dialogVisible(val) {
      this.$emit('changeStatus', val)
    },
    type(val) {
      if (val == 2) {
        this.$nextTick(() => {
          let dropbox = document.querySelector('.dropbox')
          dropbox.addEventListener('dragenter', this.onDrag, false)
          dropbox.addEventListener('dragover', this.onDrag, false)
          dropbox.addEventListener('drop', this.onDrop, false)
        })
      } else if (val == 1) {
        this.isTailoring = !1
      }
    },
    goUp: {
      handler(val) {
        if (val) {
          this.type = 2
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.options {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cropper-img {
  height: 350px;
  position: relative;
  .buttonBox {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
  }
  .show-img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: scale-down;
    }
  }
}
.img-library {
  min-height: 310px;
  display: flex;
  justify-content: space-between;
  .groupList {
    flex: 0 0 200px;
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
    margin-right: 10px;
    background-color: #f7f8fa;
    & > div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
    .enter {
      background-color: #fff;
    }
  }
  .listBox {
    flex: 1;
    height: 500px;
    display: flex;
    flex-direction: column;
  }
  .img-librar-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // padding-left: 10px;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    li {
      // width: 122px;
      // height: 122px;
      width: 106.6px;
      // height: 106.6px;
      height: 136.6px;
      margin-right: 20px;
      margin-bottom: 10px;
      position: relative;
      .el-image {
        width: 100%;
        height: 106.6px;
        /deep/ img {
          object-fit: contain;
        }
      }
      span {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 106.6px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s;
        color: #fff;
        font-size: 30px;
      }
      p {
        width: 100%;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 4px;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 48px;
    align-items: center;
    padding-right: 20px;
    background-color: #e8eef1;
  }
}
// .el-dialog__wrapper /deep/ .el-dialog__body {
//   min-height: 700px;
// }
.uploadImg {
  .chooseClass {
    display: flex;
    align-items: center;
    p {
      margin: 0 20px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .showImg {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    max-height: 260px;
    overflow: hidden;
    overflow-y: auto;
    .imgBox {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      flex-shrink: 0;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      i {
        position: absolute;
        top: -5px;
        right: -5px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .cropper {
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 80px;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .addImg {
      border-radius: 2px;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      cursor: pointer;
      border-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f7f8fa;
      position: relative;
      input {
        width: 80px;
        height: 80px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
}
</style>
